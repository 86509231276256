import axios from 'axios';
import { useEffect, useState } from 'react';
import { Auth } from './components/Auth';
import Dash from './components/Dash';
import { Typewriter } from './components/Typewriter';

export default function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [metricsData, setMetricsData] = useState({});

  async function fetchMetrics() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/metrics`);
      // console.log(res.data);
      setMetricsData(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchMetrics();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMetrics();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="header">
        <h1 style={{ marginRight: '1rem' }}>
          <Typewriter text="Ecchi Engine" delay={200} />
        </h1>

        <h5 style={{ margin: 'auto 0', padding: 0 }}>
          {`Generated Images: ${
            metricsData?.generatedImages ? metricsData?.generatedImages : 0
          }`}
        </h5>
      </div>

      <div className="app-container">
        {isAuth ? <Dash /> : <Auth setIsAuth={setIsAuth} />}
      </div>
    </>
  );
}
